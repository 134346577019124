.techTotal{
    background-color: black;
    padding:5%;
}
.techstack{
    display: flex;
    justify-content: space-evenly;
    margin: 5%;
}
.techstack h2{
    margin:  5px;
    border-bottom: 5px solid #f0f8ff;
    border-bottom-left-radius: 10px;
    padding: 0 5%;
}
.techStackTitle{
    display:flex;
    justify-content: center;
}

.techStackTitle h1{
    border-bottom: 5px solid #f0f8ff;
    border-bottom-left-radius: 10px;
    padding: 0 5%;
}

@media screen and (max-width:960px){

    .techStackTitle h1{
        font-size: 2rem;
        
    }
    .techstack{
        display: flex;
        justify-content: space-evenly;
        margin: 5% 0;
    }
    
}