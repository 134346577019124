.hero{
    display: flex;
    justify-content:space-between;
    
}
.hero img{
    border-radius: 50%;
    margin-top: 10px;
    
}
.about{

    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
    padding:3rem;
    width:40%;
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 1s;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    z-index: 1;
}
.about h1{
font-size: 4.5rem;
font-family: 'Dosis', sans-serif;
}
.about strong{
    font-size:7rem;
}

video{
    width:60%;
    background-color: #f0f8ff ;
    object-fit: cover;
    animation-name: fadeIn;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    z-index: 0;
    
}


@media screen and (max-width:1240px){
    video{
        width:70%;
    }
    .about{
        width: 30%;
    }
    .about h1{
        font-size: 3.5rem;
        
    }
    .about strong{
            font-size:5rem;
    }
}

@media screen and (max-width:960px){
    .hero{
        flex-direction: column;
    }
    .about{

        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        text-align: center;
        padding:3rem;
        width:100%;
        opacity: 0;
        animation-name: fadeIn;
        animation-delay: 1s;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        z-index: 1;
    }
    video{
        width:100%;
        background-color: #f0f8ff ;
        animation-name: fadeIn;
        object-fit: cover;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        background-color: rgba(0,0,0,.5);
        z-index: 0;
    }
}
@media screen and (max-width:400px){
    .about h1{
        font-size: 2.5rem;
        
    }
}


@keyframes shrink {
    0% {width:100%;
    }
    100%{width:50%;}
}

  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
      object-fit: cover;
    }
    50%{
        opacity: .5;
        object-fit: cover;
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
      object-fit: cover;
    }
  }
  
