.projectContainer{
    padding:5%;
    margin-bottom: 10%;
   position:relative;
  
}
.projectTitle{
    display:flex;
    justify-content: center;
}
.projectTitle h1{
    border-bottom: 5px solid #f0f8ff;
    border-bottom-left-radius: 10px;
    padding: 0 5%;
    margin-bottom: 5%;
}
.projects ul{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}

@media screen and (max-width:960px){
    .projectTitle h1{
        font-size: 2rem;
    }
}


