*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #F9FBF2;
}

h1,p,a{
  color:#F9FBF2;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#0e1c36;
}

ul{
  list-style-type: none;
}
a{
  text-decoration: none;
}


